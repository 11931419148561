import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Box, useTheme, Button } from "@mui/material";
import { marcarURL } from '../../config/functions';
import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { api } from '../../config/axios';
// ICONOS
import SaveIcon from '@mui/icons-material/Save';

const Integracion = () => {

    const [data, setData] = useState([]);
    const [dataTK, setDataTK] = useState([]);
    const [loading, setloading] = useState(true);
    const [loadingMcleod, setloadingMcleod] = useState(true);

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [fechas, setfechas] = useState({

        fechainicio: '',
        fechafin: ''

    })

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {

        window.scrollTo(0, 0);

        //buscarMcleod();

        marcarURL('integracion');
        //buscarInfomacionTK(fechas)


    }, [])

    const handleStartDateChange = (date) => {

        let fecha = date.$d.toJSON().substring(0, 10);
        
        setfechas({ ...fechas, fechainicio: fecha });

        
      };
    
      const handleEndDateChange = (date) => {
        
        let fecha = date.$d.toJSON().substring(0, 10);
        setfechas({ ...fechas, fechafin: fecha });
      };
    

    // FUNCION PARA BUSCAR CAJAS

    const buscarMcleod = async () => {


        const response = await api.get('get/mcleodOrders');

        if (response.data.success === true && response.data.result !== "Sin resultados") {

            setData(response.data.result);
            setloadingMcleod(false)

        } else {

            setData([]);

        }

    }

    const buscarInfomacionTK = async (fechas) => {


        const response = await api.get(`get/thermokingData/${fechas['fechainicio']}/${fechas['fechafin']}`);

        if (response.data.success === true && response.data.result !== "Sin resultados") {

            setDataTK(response.data.result);
            setloading(false)

        } else {

            setDataTK([]);

        }

    }

    const handleClick = async () => {

        setData([])
        setDataTK([])
        setloading(true)
        setloadingMcleod(true)
        buscarMcleod();
        buscarInfomacionTK(fechas)
        

    }

    function celsiusToFahrenheit(celsius) {
        var fahrenheit = (celsius * 9/5) + 32;
        return fahrenheit;
      }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "id",
            headerName: "orden",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "customer.name",
            headerName: "Customer name",
            flex: 2,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => params.row?.customer?.name
        },
        {
            field: "enteredUser.name",
            headerName: "Registro:",
            flex: 2,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => params.row?.enteredUser?.name
        },
        {
            field: "__statusDescr",
            headerName: "Status",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "ordered_date",
            headerName: "Fecha de la orden",
            flex: 2,
            headerAlign: "center",
            align: "center",
            valueGetter: ({ value }) => {
                const year = parseInt(value.substring(0, 4));
                const month = parseInt(value.substring(4, 6)) - 1; // Months in JavaScript are 0-based (0 = January, 1 = February, ...)
                const day = parseInt(value.substring(6, 8));
                const hour = parseInt(value.substring(8, 10));
                const minute = parseInt(value.substring(10, 12));

                // Create a Date object with the parsed values
                const date = `${day}/${month}/${year} ${hour}:${minute}`

                return date
            }
        },

    ];

    const columnsTk = [

        {
            field: "vehicleName",
            headerName: "Numero de vehiculo",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "locationDescription",
            headerName: "Ubicacion aproximada",
            flex: 2,
            headerAlign: "center",
            align: "center"
        },
        {
            field: "setPoint1",
            headerName: "SP1",
            flex: 2,
            headerAlign: "center",
            align: "center",
            valueGetter: ({ value }) => {
               
                let temperatura = celsiusToFahrenheit(value)
                return `${temperatura.toFixed(2)} °F`
            }
        },
        {
            field: "returnAir1",
            headerName: "RA1",
            flex: 2,
            headerAlign: "center",
            align: "center",
            valueGetter: ({ value }) => {
                let temperatura = celsiusToFahrenheit(value)
                return `${temperatura.toFixed(2)} °F`
            }
        },
        {
            field: "dischargeAir1",
            headerName: "DA1",
            flex: 2,
            headerAlign: "center",
            align: "center",
            valueGetter: ({ value }) => {
                let temperatura = celsiusToFahrenheit(value)
                return `${temperatura.toFixed(2)} °F`
            }
        },
        {
            field: "dataDate",
            headerName: "Fecha",
            flex: 2,
            headerAlign: "center",
            align: "center",
            valueGetter: ({ value }) => {
                var fecha = new Date(value);

                // Extrae el día, mes y año
                var dia = fecha.getDate();
                var mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, así que sumamos 1.
                var año = fecha.getFullYear();

                // Formatea los valores en "DD/MM/YYYY"
                var fechaFormateada = (dia < 10 ? '0' : '') + dia + '/' + (mes < 10 ? '0' : '') + mes + '/' + año;

                return fechaFormateada
            }
        },

    ];

    return (

        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} sx={{ padding: '20px 20px 20px 20px' }}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="Fecha Inicial"
                                name='fechainicio'
                                onChange={handleStartDateChange}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box sx={{ paddingLeft: '16px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="Fecha Final"
                                name='fechafin'
                                onChange={handleEndDateChange}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Button type="button" variant="contained" onClick={handleClick} sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                        Buscar
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        loading={loadingMcleod}
                        pageSizeOptions={[5, 10, 25]}
                        components={{ Toolbar: GridToolbar }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DataGrid
                        rows={dataTK}
                        columns={columnsTk}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        loading={loading}
                        pageSizeOptions={[5, 10, 25]}
                        getRowId={(row) => row.vehicleName}
                        components={{ Toolbar: GridToolbar }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                    />
                </Grid>
            </Grid>
        </Box>

    )
}

export default Integracion