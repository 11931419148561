import React from 'react';
import { useField } from 'formik';
import { MuiTelInput } from 'mui-tel-input'


const MyPhoneInput = ({ label, ...props }) => {

    const [field, meta, helpers] = useField(props);
    const { setValue } = helpers;

    return (

        <div>
            <MuiTelInput 
                sx={{width: '100%'}}
                defaultCountry="MX"
                label={label}
                autoComplete="off"
                value={field.value} 
                onChange={value => setValue(value)} 
                {...props}
            />
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
        </div>

    )

}

export default MyPhoneInput