import React, { useEffect, useState, useRef } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Box, useTheme, Button, IconButton } from "@mui/material";
import { marcarURL } from '../../config/functions';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { api } from '../../config/axios';
import SearchIcon from "@mui/icons-material/Search";
import Drawer from '@mui/material/Drawer';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import moment from 'moment'

const OrdenerPorEstado = () => {


    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // USES STATES
    // ===============================================

    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [open, setOpen] = useState(false);

    const [comentarios, setComentarios] = useState([])

    const [dataPrueba, setDataPrueba] = useState([])

    const [loading, setloading] = useState(true);

    useEffect(() => {

        window.scrollTo(0, 0);
        marcarURL('ordenesporestado');


    }, [])

    // ===============================================
    // FUNCION QUE SE REPITE CADA 10 SEGUNDOS
    // ===============================================

    const funcionQueseRepite = async () => {
        await new Promise(resolve => {
            const interval = setInterval(() => {
                verificarActualDeparture()

                selectFirstRow();
                resolve()
            }, 10000)
            // Limpia el intervalo al desmontar el componente
            return () => clearInterval(interval);
        })

    }

    // ===============================================
    // USE EFFECT QUE PERMITE QUE LA FUNCION SE REPITA
    // ===============================================
    useEffect(() => {

        funcionQueseRepite()
        selectFirstRow();
    }, [dataPrueba])

    // ===============================================
    // FUNCION QUE VERIFICA LA FECHA DE DEPARTO
    // ADEMAS DE ASIGNAR LAS HORAS IDLE & DWELL    
    // ===============================================
    const verificarActualDeparture = async () => {

        for (let i = 0; i < dataPrueba.length; i++) {
            const orden = dataPrueba[i].order;

            const response = await api.get(`get/verificarDeparture/${orden}`);
            if (response.status === 200) {
                let tieneComentario = await ContarComentariosOrden(orden);
                
                if(!tieneComentario){
                    dataPrueba[i].tieneComentario = false
                }else{
                    dataPrueba[i].tieneComentario = true
                }
                if (response.data.success === true && response.data.result !== "Sin resultados") {
                    if (typeof (response.data.result) !== 'string') {
                        let resultado = response.data.result[0]
                        
                        if (dataPrueba[i].order === resultado.orderDeparture) {
                            
                            if (dataPrueba[i].arrival === null) {

                                dataPrueba[i].arrival = resultado.actual_arrival
                                let dwell = calcularTiempoDwell(dataPrueba[i].arrival, dataPrueba[i].cita)


                                dataPrueba[i].DWELL = dwell

                                let idle = calcularTiempoIDLE(dataPrueba[i].arrival, dataPrueba[i].cita, dataPrueba[i].order)
                                dataPrueba[i].IDLE = idle

                                dataPrueba[i].departure = resultado.actual_departure

                                let tiempoTrancurrido = calcularTiempoTranscurrido(dwell, idle);
                                dataPrueba[i].tiempo_transcurrido = tiempoTrancurrido

                               setDataPrueba(dataPrueba)


                            } else {
                                let dwell = calcularTiempoDwell(dataPrueba[i].arrival, dataPrueba[i].cita)


                                dataPrueba[i].DWELL = dwell

                                let idle = calcularTiempoIDLE(dataPrueba[i].arrival, dataPrueba[i].cita, dataPrueba[i].order)
                                dataPrueba[i].IDLE = idle

                                dataPrueba[i].departure = resultado.actual_departure

                                let tiempoTrancurrido = calcularTiempoTranscurrido(dwell, idle);
                                dataPrueba[i].tiempo_transcurrido = tiempoTrancurrido

                                setDataPrueba(dataPrueba)
                            }
                        }

                    }


                }else{
                    if(dataPrueba[i].arrival != null){
                        let dwell = calcularTiempoDwell(dataPrueba[i].arrival, dataPrueba[i].cita)

                        dataPrueba[i].DWELL = dwell

                        let idle = calcularTiempoIDLE(dataPrueba[i].arrival, dataPrueba[i].cita, dataPrueba[i].order)
                        dataPrueba[i].IDLE = idle

                        //dataPrueba[i].departure = resultado.actual_departure

                        let tiempoTrancurrido = calcularTiempoTranscurrido(dwell, idle);
                        dataPrueba[i].tiempo_transcurrido = tiempoTrancurrido

                        setDataPrueba(dataPrueba)
                    }
                    
                }
            }
        }

    }

    const calcularTiempoDwell = (fechaLlegada, fechaCita) => {

        let fechallegadaiso = formatearFecha(fechaLlegada)
        let fechacitaiso = formatearFecha(fechaCita)

        let llegada = moment(fechallegadaiso)
        let cita = moment(fechacitaiso)

        if(llegada === null & cita === null){
            return 'blanco'
        }else if (llegada <= cita && cita <= moment()){
            let resultado = moment().diff(cita, 'minutes')
            const diferenciaEnHoras = Math.floor(resultado / 60);
            const diferenciaEnMinutos = resultado % 60;
            return `${diferenciaEnHoras.toString().padStart(2, '0')}:${diferenciaEnMinutos.toString().padStart(2, '0')}`
            
        }else if(llegada >= cita){
            let resultado = moment().diff(llegada, 'minutes')
            const diferenciaEnHoras = Math.floor(resultado / 60);
            const diferenciaEnMinutos = resultado % 60;
            return `${diferenciaEnHoras.toString().padStart(2, '0')}:${diferenciaEnMinutos.toString().padStart(2, '0')}`
            
        }else{
            return 'blanco'
        }

    }

    const calcularTiempoIDLE = (fechaLlegada, fechaCita, orden) => {
        

        let fechallegadaiso = formatearFecha(fechaLlegada)
        let fechacitaiso = formatearFecha(fechaCita)

        let llegada = moment(fechallegadaiso)
        let cita = moment(fechacitaiso)
 
        if (cita === null && llegada !== null) {
            let resultado = moment().diff(llegada, 'minutes')
            const diferenciaEnHoras = Math.floor(resultado / 60);
            const diferenciaEnMinutos = resultado % 60;
            return `${diferenciaEnHoras.toString().padStart(2, '0')}:${diferenciaEnMinutos.toString().padStart(2, '0')}`
            //AHORA - cita
        } else if (cita === null) {
            return "blanco"
        } else if (llegada >= cita) {
            return "blanco"
        } else if (llegada <= cita && moment() <= cita) {
            let resultado = moment().diff(llegada, 'minutes')
            const diferenciaEnHoras = Math.floor(resultado / 60);
            const diferenciaEnMinutos = resultado % 60;
            return `${diferenciaEnHoras.toString().padStart(2, '0')}:${diferenciaEnMinutos.toString().padStart(2, '0')}`
            //ahora - llegada
        } else if (llegada <= cita && cita <= moment()) {
            let resultado = cita.diff(llegada, 'minutes')
            // Calcula la diferencia en horas y minutos
            const diferenciaEnHoras = Math.floor(resultado / 60);
            const diferenciaEnMinutos = resultado % 60;
            return `${diferenciaEnHoras.toString().padStart(2, '0')}:${diferenciaEnMinutos.toString().padStart(2, '0')}`
            //cita - llegada
        }else{
            return "blanco"
        }

    };

    const calcularTiempoTranscurrido = (tiempoDWELL, tiempoIDLE) => {


        if ((tiempoIDLE === undefined || tiempoIDLE === 'no info')) {
            tiempoIDLE = '00:00'
        }
        if (tiempoDWELL === undefined || tiempoDWELL === 'no info') {
            tiempoDWELL = '00:00'
        }

        let resultado = sumarHoras(tiempoDWELL, tiempoIDLE)



        return resultado

    }

    const sumarHoras = (hora1, hora2) => {

        if(hora1 === 'blanco'){
            hora1 = '00:00'
        }

        if(hora2 === 'blanco'){
            hora2 = '00:00'
        }
        
        // Divide las cadenas en horas y minutos
        const partesHora1 = hora1.split(':');
        const partesHora2 = hora2.split(':');

        // Obtiene las horas y minutos como números
        const horas1 = parseInt(partesHora1[0]);
        const minutos1 = parseInt(partesHora1[1].split(' ')[0]);
        const horas2 = parseInt(partesHora2[0]);
        const minutos2 = parseInt(partesHora2[1].split(' ')[0]);

        // Sumar las horas y minutos
        let sumaHoras = horas1 + horas2;
        let sumaMinutos = minutos1 + minutos2;

        // Verificar si los minutos superan 60
        if (sumaMinutos >= 60) {
            sumaHoras++;
            sumaMinutos -= 60;
        }

        // Formatear el resultado
        const resultado = `${sumaHoras.toString().padStart(2, '0')}:${sumaMinutos.toString().padStart(2, '0')} hrs`;


        return resultado
    }


    // Función para seleccionar automáticamente la primera fila
    const selectFirstRow = () => {
        
        if (dataPrueba.length > 0) {
            setSelectedRowIds([dataPrueba[0].order]);
        }
    };

    const buscarComentariosOrden = async (orden) => {
        setComentarios([])
        const response = await api.get(`get/comentariosOrden/${orden}`);

        if (response.data.success === true && response.data.result !== "Sin resultados") {
            setComentarios(response.data.result)
        } else {
            setComentarios([])
        }


    }

    const ContarComentariosOrden = async (orden) => {

        const response = await api.get(`get/comentariosOrden/${orden}`);

        if (response.data.success === true && response.data.result !== "Sin resultados") {
            return true
        } else {
            return false
        }


    }


    const formatearFecha = (fechaHoraOriginal) => {

        // Extraer partes de la fecha y hora original
        const anio = fechaHoraOriginal.slice(0, 4);
        const mes = fechaHoraOriginal.slice(4, 6);
        const dia = fechaHoraOriginal.slice(6, 8);
        const hora = fechaHoraOriginal.slice(8, 10);
        const minuto = fechaHoraOriginal.slice(10, 12);
        const segundo = fechaHoraOriginal.slice(12, 14);
        const offsetHorario = fechaHoraOriginal.slice(14);

        // Crear un objeto de fecha en el formato deseado
        const fechaHoraTransformada = new Date(`${anio}-${mes}-${dia}T${hora}:${minuto}:${segundo}${offsetHorario.slice(0, 3)}:${offsetHorario.slice(3)}`);

        // Formatear la fecha y hora transformada como una cadena
        const fechaHoraFormateada = fechaHoraTransformada.toISOString();

        return fechaHoraFormateada
    }

    const toggleDrawer = (isOpen) => {
        setOpen(isOpen)
    }

    // ===============================================
    // FUNCION QUE DIBUJA EL DRAWER
    // ===============================================

    const cerrarDrawer = () => {
        toggleDrawer(false)
        setComentarios([])
    }
    function DibujarDrawer({ datos }) {

        return (
            <Drawer anchor="right" open={open} onClose={() => cerrarDrawer()}>
                <Box width={700} sx={{ padding: '10px' }}>
                    <IconButton aria-label="delete" onClick={() => toggleDrawer(false)}>
                        <CloseIcon />
                    </IconButton> COMENTARIOS DE LA ORDEN
                    {datos.length > 0 ? (
                        datos.map((dato, index) => (
                            <Box sx={{ border: '2px solid, #FFFFFF', width: '100%', marginTop: '10px', marginBottom: '10px' }}>
                                <Card sx={{ minWidth: 275 }} key={dato + index}>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} gutterBottom>
                                            Tipo de comentario:
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            {dato.tipo_comentario}
                                        </Typography>
                                        <Typography variant="h5" component="div">
                                            Usuario:
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            {dato.user}
                                        </Typography>
                                        <Typography variant="h5" component="div">
                                            Comentario:
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            {dato.comentario}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                        ))

                    ) : (
                        <Typography sx={{ fontSize: 30, textAlign: 'center', }} gutterBottom>
                            Sin información
                        </Typography>
                    )}


                </Box>
            </Drawer>
        )
    }


    // ===============================================
    // SECCION DE PRUEBAS 
    // ===============================================



    const llenarTabla2 = async () => {
        const response2 = await api.get('get/ordenerporestado');

        if (response2.data.success === true && response2.data.result !== "Sin resultados") {

            setDataPrueba(response2.data.result);
            setloading(false)
            selectFirstRow()

        } else {

            setDataPrueba([]);

        }
    }

    const columns2 = [

        {
            field: "order",
            headerName: "orden",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "truck",
            headerName: "truck",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "ubicacion_actual",
            headerName: "ubicacion actual",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "planta",
            headerName: "Planta",
            flex: 2,
            cellClassName: "name-column--cell-text-normal",
            headerAlign: "center",
            align: "center",

        },
        {
            field: "cliente",
            headerName: "Cliente",
            flex: 2,
            cellClassName: "name-column--cell-text-normal",
            headerAlign: "center",
            align: "center",

        },
        {
            field: "arrival",
            headerName: "Actual Arrival (DD/MM/YYYY)",
            flex: 2,
            headerAlign: "center",
            cellClassName: "name-column--cell-text-normal",
            align: "center",
            valueGetter: ({ value }) => {
                if (value != null) {
                    const year = value.substring(0, 4);
                    const month = value.substring(4, 6).padStart(2, '0'); // Months in JavaScript are 0-based (0 = January, 1 = February, ...)
                    const day = value.substring(6, 8).padStart(2, '0');
                    const hour = value.substring(8, 10).padStart(2, '0');
                    const minute = value.substring(10, 12);

                    // Create a Date object with the parsed values
                    const date = `${day}/${month}/${year} ${hour}:${minute}`

                    return date
                } else {
                    return 'sin fecha'
                }

            }
        },
        {
            field: "cita",
            headerName: "Fecha de cita (DD/MM/YYYY)",
            flex: 2,
            cellClassName: "name-column--cell-text-normal",
            headerAlign: "center",
            align: "center",
            valueGetter: ({ value }) => {
                const year = value.substring(0, 4);
                const month = value.substring(4, 6).padStart(2, '0'); // Months in JavaScript are 0-based (0 = January, 1 = February, ...)
                const day = value.substring(6, 8).padStart(2, '0');
                const hour = value.substring(8, 10).padStart(2, '0');
                const minute = value.substring(10, 12);

                // Create a Date object with the parsed values
                const date = `${day}/${month}/${year} ${hour}:${minute}`

                return date
            }
        },
        {
            field: "IDLE",
            headerName: "IDLE(H:M)",
            flex: 2,
            cellClassName: "name-column--cell-text-normal",
            headerAlign: "center",
            align: "center",
            valueGetter: ({ value }) => {
                if(value === 'blanco' || value === undefined ){
                    return '-'
                }else{
                    return value
                }
            }
            
        },
        {
            field: "DWELL",
            headerName: "DWELL(H:M)",
            flex: 2,
            cellClassName: "name-column--cell-text-normal",
            headerAlign: "center",
            align: "center",
            valueGetter: ({ value }) => {
                if(value === 'blanco' || value === undefined ){
                    return '-'
                }else{
                    return value
                }
            }
            
        },
        {
            field: "departure",
            headerName: "Actual departure (DD/MM/YYYY)",
            flex: 2,
            headerAlign: "center",
            align: "center",
            valueGetter: ({ value }) => {
                if (value != undefined) {
                    const year = value.substring(0, 4);
                    const month = value.substring(4, 6).padStart(2, '0'); // Months in JavaScript are 0-based (0 = January, 1 = February, ...)
                    const day = value.substring(6, 8).padStart(2, '0');
                    const hour = value.substring(8, 10).padStart(2, '0');
                    const minute = value.substring(10, 12);

                    // Create a Date object with the parsed values
                    const date = `${day}/${month}/${year} ${hour}:${minute}`

                    return date
                } else {
                    return 'no info';
                }
            }
        },
        {
            field: "tiempo_transcurrido",
            headerName: "Tiempo transcurrido (H:M:S)",
            flex: 2,
            headerAlign: "center",
            align: "center",
            valueGetter: ({ value }) => {
                if(value === 'blanco' || value === undefined ){
                    return '-'
                }else{
                    return value
                }
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => (
                params.row.tieneComentario === true  && params.row.tieneComentario !== undefined
                ? (
                    <Button
                    variant="contained"
                    
                    sx={{
                        backgroundColor: '#4BB543',
                        color: 'white',
                    }}
                    onClick={() => {
                        toggleDrawer(true)
                        buscarComentariosOrden(params.row.order)
                    }}
                >
                    Ver comentarios
                </Button>
                ):
                (
                    <Button
                    variant="contained"
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: 'white',
                    }}
                    onClick={() => {
                        toggleDrawer(true)
                        buscarComentariosOrden(params.row.order)
                    }}
                >
                    Ver comentarios
                </Button>
                )

            ),
        },

    ];

    const handleClick2 = async () => {

        setDataPrueba([])
        llenarTabla2()
        selectFirstRow()


    }

    return (

        <Box sx={{
            flexGrow: 1,
            "& .MuiDataGrid-root": {
                border: "none",
            },
            "& .MuiDataGrid-cell": {
                borderBottom: "none",
            },
            "& .name-column--cell": {
                color: colors.greenAccent[300],
            },
            "& .name-column--cell-text-normal": {
                fontSize: '12px',
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`
            }
        }}>

            <Grid container spacing={2} sx={{ padding: '20px 20px 20px 20px' }}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>

                    <Button type="button" variant="contained" onClick={handleClick2} sx={{ color: "white" }} color="success" startIcon={<SearchIcon />}>
                        Buscar
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DataGrid
                        sx={{
                            '& .red': {
                                color: '#F31F1F',
                                fontSize: '20px'
                            },
                            '& .normal': {
                                fontSize: '20px'
                            },
                        }}
                        rows={dataPrueba}
                        columns={columns2}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        getRowId={(row) => row.truck}
                        autoHeight
                        loading={loading}
                        pageSizeOptions={[5, 10, 25]}
                        components={{ Toolbar: GridToolbar }}
                        getCellClassName={(params) => {
                            if (params.field === 'order' || params.field === 'truck' || params.field === 'planta' || params.field === 'ubicacion_actual' || params.field === 'arrival' || params.field === 'cita' || params.field === 'departure' || params.value == null || params.value == undefined || params.value == 'cliente' ) {
                                return '';
                            } else {
                                if (params.value[0] == 0 && params.value[1] >= 3) {
                                    return 'red'
                                } else {
                                    if (params.value[0] >= 1) {
                                        return 'red'
                                    } else {
                                        return 'normal'
                                    }
                                }
                            }


                        }}
                    />
                </Grid>
                <DibujarDrawer datos={comentarios} />
            </Grid>

        </Box>




    )
}

export default OrdenerPorEstado