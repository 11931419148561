import React, {useState, useEffect} from 'react'
import { ColorModeContext, useMode } from './theme';
import { tokens } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";

// COMPONENTES
import Calendar from './scenes/calendar';

// COMPONENTES EBT SUITE

import Login from './pages/login/Login';
import Catalogos from './pages/catalogos/Catalogos';
import Puestos from './pages/catalogos/puestos/Puestos';
import Usuarios from './pages/catalogos/usuarios/Usuarios';
import Perfiles from './pages/catalogos/perfiles/Perfiles';
import Departamentos from './pages/catalogos/departamentos/Departamentos';
import Integracion from './pages/Integracion/integracion';
import OrdenerPorEstado from './pages/Integracion/ordenesPorEstado';


function App() {

  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  // ===============================================  
  // LOGIN
  // =============================================== 

  const [showLogin, setShowLogin] = useState(true);

  useEffect(() => {

    if(Cookies.get('loginLink') === "1"){

      setShowLogin(false);

      const ruta = Cookies.get('moduloLink');

      navigate(`/${ruta}`);

    }

  },[])

  // ===============================================

  return (

    <ColorModeContext.Provider value={colorMode}>

      <ThemeProvider theme={theme}>

        <CssBaseline>

          <div className='app'>

            {showLogin ? null : <Sidebar />}

            <main 
              className='content' 
              style={{
                backgroundColor: colors.primary[1000], 
                minHeight: '100vh',
                overflow: 'auto'
              }}>

              {showLogin ? null : <Topbar setShowLogin={setShowLogin}/>}

              <Routes>

                {/* INICIO */}

                <Route path='/' element={<Login setShowLogin={setShowLogin}/>}/>
                <Route path='/dashboard' element={<Dashboard/>}/>

                {/* CATALOGOS */}

                <Route path='/catalogos' element={<Catalogos/>}/>
                <Route path='/usuarios' element={<Usuarios/>}/>
                <Route path='/puestos' element={<Puestos/>}/>
                <Route path='/perfiles' element={<Perfiles/>}/>
                <Route path='/departamentos' element={<Departamentos/>}/>

                {/* INTEGRACION */}

                <Route path='/integracion' element={<Integracion/>}/>
                <Route path='/ordenesporestado' element={<OrdenerPorEstado/>}/>

                {/* CALENDARIO */}

                <Route path='/calendar' element={<Calendar/>}/>

              </Routes>

            </main>

          </div>

        </CssBaseline>

      </ThemeProvider>

    </ColorModeContext.Provider>

  )
  
}

export default App