import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupIcon from '@mui/icons-material/Group';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PublicIcon from '@mui/icons-material/Public';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BadgeIcon from '@mui/icons-material/Badge';
import DescriptionIcon from '@mui/icons-material/Description';
import TocIcon from '@mui/icons-material/Toc';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import GroupsIcon from '@mui/icons-material/Groups';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import RouteIcon from '@mui/icons-material/Route';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaidIcon from '@mui/icons-material/Paid';

/*=============================================
ICONOS PARA EL MODULO DE CATALOGOS
=============================================*/

export const iconos = {

    ApartmentIcon,
    GroupIcon,
    LocalShippingIcon,
    PublicIcon,
    RecentActorsIcon,
    AssignmentIndIcon,
    AccountCircleIcon,
    BadgeIcon,
    DescriptionIcon,
    TocIcon,
    MenuBookIcon,
    EmojiTransportationIcon,
    LocationOnIcon,
    PersonPinCircleIcon,
    InsightsIcon,
    GroupsIcon,
    AltRouteIcon,
    RouteIcon,
    AssignmentIcon,
    AccountBalanceIcon,
    PaidIcon,
    LocalShippingIcon

};

// * CADA VEZ QUE SE AGREGUE UN MODULO DE CATALOGO AGREGAR EL ICONO EN EL MODULO DE CATALOGOS TAMBIEN*

/*=============================================
MODULOS PARA EL MENU DINAMICO
=============================================*/

// * CADA VEZ QUE SE AGREGUE UN MODULO NUEVO, FAVOR DE AGREGARLO A ESTE ARRAY PARA PODER UTILIZARLO DE MANERA DINAMICA CON SUS RESPECTIVOS ATRIBUTOS*

export const modulos = [

    // CATALOGO

    { type: 'subheader', label: 'Catalogo', icono: 'TocIcon'},

    { value: 'catalogo.Usuarios', label: 'Usuarios', icono: 'AccountCircleIcon', url: 'usuarios' },
    { value: 'catalogo.Departamentos', label: 'Departamentos', icono: 'RecentActorsIcon', url: 'departamentos' },
    { value: 'catalogo.Puestos', label: 'Puestos', icono: 'AssignmentIndIcon', url: 'puestos' },
    { value: 'catalogo.Perfiles', label: 'Perfiles', icono: 'BadgeIcon', url: 'perfiles' },

    //APIS

    { type: 'subheader', label: 'Operaciones', icono: 'LocalShippingIcon'},

    { value: 'operaciones.Integracion', label: 'Integracion', icono: 'PaidIcon', url: 'integracion' },
    { value: 'operaciones.OrdenesPorEstado', label: 'Ordenes', icono: 'LocalShippingIcon', url: 'ordenesporestado' }
];

