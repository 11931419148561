import React from 'react';
import { useField } from 'formik';
import { Switch, FormControlLabel, FormHelperText  } from "@mui/material";

const MySwitch = ({ label, ...props }) => {

    const [field, meta, helpers] = useField(props);

    const handleChange = (e) => {
        const newValue = e.target.checked ? 1 : 0;
        helpers.setValue(newValue);
    };

    return (
        <>
        <FormControlLabel
            control={
                <Switch
                    {...field}
                    {...props}
                    checked={field.value === 1}
                    color="secondary"
                    onChange={handleChange}
                    sx={{transform : "scale(1.7)"}}
                />
            }
            label={label}
            labelPlacement="top"
        />
        {meta.touched && meta.error && (
            <FormHelperText error>
                {meta.error}
            </FormHelperText>
        )}
        </>
    );

}

export default MySwitch